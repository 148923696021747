<template>
	<div class="page-form" v-if="langInfo">
		<div class="app-logo">
			<img class="logo" src="../../assets/images/logo-white.png">
		</div>
		<!--  产品 评论 信息 -->
		<div class="app-form">
			<div class="form-title">
				{{langInfo.newLangInfo.formPage.title20}}
			</div>
			<div class="form-item">
				<!-- 订单id -->
				<div class="item-title">
					<img class="icon" style="width: 18px;height: 16px;" src="../../assets/images/icons/book.png" />
					<div class="text">
						{{ langInfo.newLangInfo.questPage.orderid }}
					</div>
				</div>
				<div class="item-value">
					<input v-model="formData.order_id" class="value-input"
						:placeholder="langInfo.newLangInfo.registerPage.mandatory">
				</div>
			</div>
			<!-- 评论 -->
			<div class="form-item">
				<div class="item-title">
					<img class="icon" src="../../assets/images/icons/res.png" />
					<div class="text">
						{{langInfo.registration.member.EvaluationString}}
					</div>
				</div>
				<div class="item-value">
					<input v-model="formData.review_url" class="value-input"
						:placeholder="langInfo.newLangInfo.registerPage.mandatory">
				</div>
			</div>
			<!-- 截图 -->
			<div class="form-item">
				<div class="item-title">
					<img class="icon" style="width: 16px;height: 18px;" src="../../assets/images/icons/commit.png">
					<div class="text">
						{{langInfo.registration.member.EvaluationURl}}
					</div>
				</div>
				<van-uploader v-model="fileList" class="item-upload" :max-size="5000 * 1024" :after-read="afterRead">
					<div class="upload-style">
						<img class="icon" src="../../assets/images/icons/upload.png">
						<div class="u-text">
							{{ langInfo.newLangInfo.questPage.upload }}
						</div>
					</div>
				</van-uploader>
			</div>
		</div>

		<div class="form-buttons">
			<div class="button-blue" @click="handSubmit">
				{{langInfo.newLangInfo.registerPage.submit}}
			</div>
		</div>

		<!--  审核 -->
		<van-popup v-model.sync="showMsg" :close-on-click-overlay="false"
			:overlay-style="{background:'rgba(0,0,0,0.4)'}" :round="true">
			<div class="msg-box">
				<div class="msg-close" @click="showMsg = false">
					<van-icon name="close" />
				</div>
				<div class="msg-logo">
					<img class="logo" src="../../assets/images/success.png">
				</div>
				<!-- 提交成功提示 -->
				<div class="msg-title">
					{{langInfo.newLangInfo.formPage.succTitle}}
				</div>
				<!-- 审核 -->
				<div class="msg-content">
					{{langInfo.newLangInfo.formPage.succText20}}
				</div>
				<!-- 代金卷 -->
				<!-- <div class="msg-content" style="margin-top: 12px;">
					The voucher will be sent via Store Messenger once approved.
				</div> -->
			</div>
		</van-popup>
		<login-model :show="showLogin" ismask @click="showLogin = false" />
	</div>
</template>

<script>
	import {
		List
	} from '../../util/en_config_new';
	import {
		langData
	} from '../../util/lang_config_new';
	import {
		Popup,
		Uploader,
		Icon,
		Toast
	} from 'vant';
	import appCheckbox from '../../components/app-checkbox/app-checkbox.vue';
	import loginModel from '../../components/login-model/login-model.vue';
	export default {
		components: {
			appCheckbox,
			[Popup.name]: Popup,
			[Uploader.name]: Uploader,
			[Icon.name]: Icon,
			loginModel
		},
		data() {
			return {
				orderPay: 3,
				orderPayList: ['Shopee', 'Lazada', 'TikTok Shop', 'Offline Channels', 'Other'],
				fileList: [],
				showMsg: false,
				showForm: false,
				showLogin: false,
				infoData: {},
				formData: {},
				langInfo: null,
				showDataInfo: null, //包含手机号校验正则
			};
		},
		created() {
			this.init();
		},
		methods: {
			init() {
				if (localStorage.getItem('NationName') && localStorage.getItem('LoginInfo')) {
					this.infoData = JSON.parse(localStorage.getItem('LoginInfo'));
					this.infoData.Nation = localStorage.getItem('NationName');
					this.langInfo = this.setLang();
				} else {
					this.showLogin = true;
				}
			},
			afterRead(file) {
				// 上传图片到图片服务器
				let fd = new FormData();
				fd.append('file', file.file);
				this.axios.post(`/api/upload/file`, fd, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					}).then((res) => {
						if (res.data.code == 200) {
							this.formData.review_filename = res.data.filename;
						} else {
							this.formData.review_filename = '';
							Toast(JSON.stringify(res));
						}
					})
					.catch((err) => {
						this.uploading = false
						Toast(JSON.stringify(err));
					});
			},
			handSubmit() {
				if (!this.formData.order_id) {
					Toast(this.langInfo.registration.member.OrderTips);
					return;
				}
				if (!this.formData.review_url) {
					Toast(this.langInfo.registration.member.EvaluationString);
					return;
				}
				if (!this.formData.review_filename) {
					Toast(this.langInfo.registration.member.EvaluationURlTips);
					return;
				}
				this.formData.customer_id = this.infoData.User_id;
				this.axios.post(`/api/addreview/`, this.formData).then((res) => {
						let resstr = res.data;
						if (resstr.code === 200) {
							this.showMsg = true;
						} else {
							if (resstr.msg !== '') {
								Toast(resstr.msg);
							} else {
								Toast('Wrong');
							}
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			setLang() {
				let Info = langData.filter((res) => res.Nation == this.infoData.Nation)[0];
				this.showDataInfo = List.filter((res) => res.Nation == this.infoData.Nation)[0];
				return Info == undefined ? langData[0] : Info;
			}
		}
	};
</script>

<style lang="less">
	.page-form {
		width: 100vw;
		height: 100vh;
		background: #4E5E9A;
		position: relative;

		.app-logo {
			padding: 54px 88px 54px;
			display: flex;
			justify-content: center;

			.logo {
				width: 150px;
				height: 50px;
			}
		}

		.app-form {
			width: calc(100vw - 48px);
			height: 80vh;
			border-radius: 24px 24px 0px 0px;
			background: #FFFFFF;
			padding: 24px;

			.form-title {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding-bottom: 24px;
			}

			.form-item {
				display: flex;
				flex-direction: column;

				.item-title {
					display: flex;
					align-items: center;
					padding-bottom: 5px;
					padding-top: 5px;

					.icon {
						width: 18px;
						height: 18px;
					}

					.text {
						padding-left: 8px;
						color: #4D4D4D;
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 600;
						padding: 4px;
					}
				}

				.item-value {
					border-radius: 8px;
					overflow: hidden;
					background: #F8F8F8;
					padding: 0px 24px;

					.value-input {
						width: 100%;
						height: 32px;
						line-height: 32px;
						background: #F8F8F8;
						border: 0px;
					}

					input {
						outline: none;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 500;
						color: #333333;
					}

					input::placeholder {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 500;
						color: #9FA0A2;
					}
				}
			}

			.item-upload {
				height: 66px;
				border-radius: 8px;
				background: #F8F8F8;
				overflow: hidden;

				.upload-style {
					display: flex;
					justify-content: center;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					height: 66px;

					.icon {
						width: 18px;
						height: 18px;
					}

					.u-text {
						//line-height: 0px;
						color: #C8C9CD;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
					}
				}

				.van-uploader__input-wrapper {
					width: 100%;
				}

				.van-uploader__upload {
					margin: 0;
					width: 100%;
					height: 66px;
					line-height: 66px;
				}

				.van-uploader__preview {
					margin: 0px;
					width: 100%;
					display: flex;
					justify-content: center;

					.van-uploader__preview-image {
						margin: 0px;
						width: 100%;
						height: 66px;
					}
				}
			}
		}


		.form-buttons {
			width: calc(100vw - 48px);
			display: flex;
			align-items: center;
			justify-content: center;
			position: fixed;
			bottom: 0px;
			padding: 18px 24px;
			background: #FFFFFF;
			z-index: 99;

			.button-blue {
				width: 80%;
				height: 36px;
				line-height: 36px;
				background: #4E5E9A;
				color: #ffffff;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 600;
				text-align: center;
				border-radius: 24px;
			}
		}

		.msg-box {
			width: 70vw;
			height: 260px;
			border-radius: 18px;
			padding: 24px;

			.msg-close {
				text-align: right;
			}

			.msg-logo {
				text-align: center;

				.logo {
					width: 80px;
					height: 90px;
				}
			}

			.msg-title {
				text-align: center;
				font-size: 18px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 600;
				color: #245BD9;
				padding: 12px;
			}

			.msg-content {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 500;
				color: #323233;
			}
		}
	}
</style>
